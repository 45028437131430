<script setup lang="ts">
const emits = defineEmits<{ selected: []; cancel: [] }>()

const {
  breadcrumbsDestinationsIds,
  data,
  fetchAllDestinations,
  fetchDestinationsAndHouses,
  fetchTopDestination,
  goToHouse,
  keepLastSearchQuery,
  meta,
  resetDestinationsAndHouses,
  setMeta,
  setBreadcrumbsDestinationsIds,
} = useSearchInput()

const inputSearch = computed({
  get() {
    return meta.value.searchQuery
  },
  set(value) {
    setMeta('searchQuery', value)
  },
})

const inputSearchRef = ref<HTMLInputElement>()

onMounted(async () => {
  resetDestinationsAndHouses()
  await Promise.all([fetchTopDestination(), fetchAllDestinations()])

  inputSearchRef.value?.focus()
})

const handleAfterClick = () => {
  keepLastSearchQuery()

  emits('selected')

  if (data.value.location?.type === 'house') {
    return goToHouse()
  }
}

const onInput = async () => {
  setBreadcrumbsDestinationsIds([])
  await fetchDestinationsAndHouses(inputSearch.value)
}

watch(
  () => data.value.location.name,
  (value) => {
    inputSearch.value = value || ''
  },
)

const handleAfterSelected = () => {
  inputSearch.value = ''
  emits('selected')
}

const handleCancel = () => {
  inputSearch.value = ''
  emits('cancel')
}
</script>

<template>
  <div>
    <div class="mb-8 flex items-center">
      <div
        class="relative flex w-full items-center rounded border border-gray-700 bg-gray-100 py-2.5 pl-3 pr-8"
      >
        <BaseIcon name="search" class="mr-2" :size="1.2" />
        <input
          ref="inputSearchRef"
          v-model="inputSearch"
          :placeholder="$t('search.placeholder')"
          autocomplete="off"
          class="w-full bg-gray-100"
          type="text"
          @input="onInput"
        />
        <button
          v-show="inputSearch.length"
          class="absolute inset-y-0 right-2"
          type="button"
          @click="inputSearch = ''"
        >
          <BaseIcon color="text-gray-700" name="close" :size="1.1" />
        </button>
      </div>
      <button
        class="ml-2 flex-none text-xs font-semibold"
        type="button"
        @click="handleCancel"
      >
        {{ $t('action.cancel') }}
      </button>
    </div>

    <template v-if="!breadcrumbsDestinationsIds.length && !inputSearch.length">
      <SearchInputV2LocationCountries
        class="mb-6"
        @close="handleCancel"
        @selected="handleAfterSelected"
      />

      <SearchInputV2LocationOptions
        :search-query="inputSearch"
        @option-clicked="handleAfterClick"
      />
    </template>
    <SearchInputV2LocationAll
      v-else-if="breadcrumbsDestinationsIds.length"
      @selected="handleAfterSelected"
    />
    <div v-else>
      <SearchInputV2LocationOptions
        :search-query="inputSearch"
        with-image
        @option-clicked="handleAfterClick"
      />
    </div>
  </div>
</template>
